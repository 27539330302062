import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useDeleteStateMutation, useGetStateMutation, useUpdateStateMutation } from "../../../store/api/endpoints/stateApiSlice";
import { alone } from "../../../store/slices/estado";
import { ButtonIcon } from "../../atoms";
import { ConfirmDelete } from "../../elementos";
import { Loading, Volver } from "../../molecule";
import { HeaderVista } from "../../organism";
import { EstadoEdit } from "../../organism/estado/EstadoEdit";
import { openFeedback } from "../../../store/slices/feedback";

const EstadoEditTemplate = () => {
  const dispatch = useDispatch();
  const [estado, setestado] = useState([]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [getState, responseState] = useGetStateMutation();
  const [deleteState, responseDeleteState] = useDeleteStateMutation();
  const [updateState, responseUpdateState] = useUpdateStateMutation();

  const id = searchParams.get("id");

  const handleActivate = () => {
    let data = { ...estado, activo: true };
    if (data) {
      updateState({ id, datos: data });
    }
  };

  useEffect(() => {
    if (responseState.isSuccess) {
      setestado(responseState?.data?.data);
      dispatch(alone(responseState?.data?.data));
    }
    if (responseUpdateState.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha actualizado el registro!",
        })
      );
      navigate(`${PRIVATE_ROUTES.FICHA_STATE}?id=${id}`);
    }
  }, [responseState.isSuccess, , responseUpdateState.isSuccess]);

  useEffect(() => {
    getState(id);
  }, []);

  if (responseState.isLoading || responseUpdateState.isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <HeaderVista
        start={<Volver label="Volver" to={`${PRIVATE_ROUTES.FICHA_STATE}?id=${id}`} />}
        titulo={"Editando estado"}
        end={
          <>
            {estado?.activo ? (
              <ConfirmDelete id={id} slice={deleteState} response={responseDeleteState} returnUrl={PRIVATE_ROUTES.LIST_STATES} variant="outlined" />
            ) : (
              <ButtonIcon variant="outlined" noLink startIcon={<PlayCircleOutlineIcon />} handleClick={handleActivate}>
                Activar
              </ButtonIcon>
            )}
          </>
        }
      />
      <EstadoEdit id={id} editting={true} resposeState={responseUpdateState} updateState={updateState} />
      <br />
    </Container>
  );
};

export default EstadoEditTemplate;
