import AddIcon from "@mui/icons-material/Add";
import { Container } from "@mui/material";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { columnsState } from "../../../constants/TitleTables";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useGetStatesMutation } from "../../../store/api/endpoints/stateApiSlice";
import { alone, getListEstados, getPagination, ListEstados, setPagination } from "../../../store/slices/estado";
import { ButtonIcon } from "../../atoms";
import { DataTable, Loading, TableRow, TableRowEstado } from "../../molecule";
import { HeaderVista } from "../../organism";

const EstadosTemplate = () => {
  const storePage = useSelector(getPagination, shallowEqual);
  const listEstados = useSelector(getListEstados, shallowEqual);
  const [getStatesPagination, { isLoading: isLoadingEstados, isSuccess: isSuccessEstados, data: dataEstados }] = useGetStatesMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccessEstados) {
      const data = dataEstados.data;
      dispatch(ListEstados(data.docs));
      dispatch(setPagination(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessEstados]);

  useEffect(() => {
    dispatch(alone());
  }, []);

  useEffect(() => {
    let newPagination = {
      limit: storePage.limit === 0 ? 50 : storePage.limit,
      page: storePage.page,
    };
    getStatesPagination(newPagination);
  }, [storePage.page, storePage.limit]);

  if (isLoadingEstados) return <Loading />;

  return (
    <Container maxWidth="lg">
      <HeaderVista end={<ButtonIcon variant="outlined" size="small" color="primary" startIcon={<AddIcon />} to={PRIVATE_ROUTES.NEW_STATES} children="Nuevo" />} titulo="Estados comunas" />

      <DataTable columns={columnsState} rows={listEstados} RowComponent={TableRowEstado} pagination={storePage} handlePagination={setPagination} label="Datos" to={`${PRIVATE_ROUTES.FICHA_STATE}`} />
      <br />
    </Container>
  );
};

export default EstadosTemplate;
