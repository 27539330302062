import { tiendasApi } from "../api";

export const empresaApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getEmpresas: builder.mutation({
      query: ({ page, cantRows, comunas }) => {
        const queryParams = new URLSearchParams({
          page,
          cantRows,
          comunas,
        }).toString();
        return {
          url: `/empresas?${queryParams}`,
          method: "GET",
        };
      },
    }),
    getEmpresa: builder.mutation({
      query: (id) => ({
        url: "/empresas/" + id,
        method: "GET",
      }),
    }),
    createEmpresa: builder.mutation({
      query: (body) => ({
        url: "/empresas",
        method: "POST",
        body: body,
      }),
    }),
    updateEmpresa: builder.mutation({
      query(element) {
        const { id, data } = element;
        return {
          url: `/empresas/${id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    deleteEmpresa: builder.mutation({
      query: (id) => ({
        url: `/empresas/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useGetEmpresaMutation, useGetEmpresasMutation, useUpdateEmpresaMutation, useDeleteEmpresaMutation, useCreateEmpresaMutation } = empresaApiSlice;
