import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useUploadPartialMutation } from "../../../store/api/endpoints/tipoEspecieArbolApiSlice";
import { getListEstadosEmpresa } from "../../../store/slices/estadoEmpresa";
import { openFeedback } from "../../../store/slices/feedback";
import { aloneEmpresa, getSucesoEmpresa, updateImagesEmpresas } from "../../../store/slices/sucesos";
import { getListTipoCortesEmpresa } from "../../../store/slices/tipoCortesEmpresa";
import { getListTipoIncidenteEmpresa } from "../../../store/slices/tipoIncidentesEmpresa";
import { Loading, SelectSmall, SmallInput } from "../../molecule";
import { ListImages } from "../ListImages";
import { style } from "./styles";
import { useGetSucesoMutation } from "../../../store/api/endpoints/sucesosApiSlice";

const FormSucesoEmpresa = React.memo(({}) => {
  const debounceTimeout = React.useRef(null);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [getSucesoAlone, responseSuceso] = useGetSucesoMutation();

  const suceso = useSelector(getSucesoEmpresa, shallowEqual);
  const states = useSelector(getListEstadosEmpresa, shallowEqual);
  const corteEmpresas = useSelector(getListTipoCortesEmpresa, shallowEqual);
  const incidencias = useSelector(getListTipoIncidenteEmpresa, shallowEqual);
  const [updatePartial, responseUpdatePartial] = useUploadPartialMutation();
  const id = searchParams.get("id");
  const onChange = (value, key) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    const attribute = key.split(".")[1];
    dispatch(aloneEmpresa({ [attribute]: value }));
    debounceTimeout.current = setTimeout(() => {
      updatePartial({ campo: key, valor: value, id });
    }, 1000);
  };

  const onUploadImage = (key, value) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    dispatch(updateImagesEmpresas(value));
    debounceTimeout.current = setTimeout(() => {
      updatePartial({ campo: key, valor: value, id });
    }, 1000);
  };

  React.useEffect(() => {
    if (responseUpdatePartial.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha actualizado el registro!",
        })
      );
      getSucesoAlone({ id, isFicha: false });
    }
    if (responseUpdatePartial.error) {
      dispatch(
        openFeedback({
          severity: "error",
          message: "¡No se pudo actualizar el registro!",
        })
      );
    }
  }, [responseUpdatePartial.isSuccess, responseUpdatePartial.error]);

  if (responseUpdatePartial.isLoading || responseSuceso.isLoading) return <Loading />;

  return (
    <List sx={style} aria-label="mailbox folders">
      <ListItem>
        <ListItemText sx={{ display: "flex", justifyContent: "center", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700, fontSize: "1.2rem" }}>Informe empresa</Typography>} />
      </ListItem>
      <Divider component="li" />
      <ListItem sx={{ padding: "8px 16px" }}>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Incidencia:</Typography>} />
        <SelectSmall lista={incidencias} value={suceso?.incidencia} name={"datos_empresa.incidencia"} onChange={onChange} />
      </ListItem>
      <Divider component="li" />
      <ListItem sx={{ padding: "8px 16px" }}>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Estado:</Typography>} />
        <SelectSmall lista={states} value={suceso?.estado_empresa} name={"datos_empresa.estado_empresa"} onChange={onChange} />
      </ListItem>
      <Divider component="li" />
      <ListItem sx={{ height: "100%", padding: "8px 16px" }}>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Corte de calle:</Typography>} />
        <SelectSmall lista={corteEmpresas} value={suceso?.corte_de_calle} name={"datos_empresa.corte_de_calle"} onChange={onChange} />
      </ListItem>
      <Divider component="li" />
      <ListItem sx={{ padding: "8px 16px" }}>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Observaciones:</Typography>} />
        <SmallInput restricciones={["alfaNumerico"]} multiline value={suceso?.observaciones} onChange={onChange} name={"datos_empresa.observaciones"} />
      </ListItem>
      <Divider component="li" />
      <ListItem sx={{ height: "100%", padding: "8px 16px" }}>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Fotos de comuna:</Typography>} />
        <ListImages imagenes={suceso?.fotos_empresa ?? []} ruta="datos_empresa.fotos_empresa" handleChange={onUploadImage} editable={true} />
      </ListItem>
    </List>
  );
});

export default FormSucesoEmpresa;
