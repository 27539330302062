import EditIcon from "@mui/icons-material/Edit";
import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { alone } from "../../../store/slices/estado";
import { ButtonIcon } from "../../atoms";
import { Loading, Volver } from "../../molecule";
import { HeaderVista } from "../../organism";
import { EstadoEdit } from "../../organism/estado/EstadoEdit";
import { useGetStateEmpresasMutation } from "../../../store/api/endpoints/stateEmpresasApiSlice";

const EstadoEmpresasFichaTemplate = () => {
  const dispatch = useDispatch();
  const [estado, setestado] = useState([]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [getState, responseState] = useGetStateEmpresasMutation();

  const id = searchParams.get("id");

  useEffect(() => {
    if (responseState.isSuccess) {
      setestado(responseState?.data?.data);
      dispatch(alone(responseState?.data?.data));
    }
  }, [responseState.isSuccess]);

  useEffect(() => {
    getState(id);
  }, []);

  if (responseState.isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <HeaderVista
        start={<Volver label="Volver" to={PRIVATE_ROUTES.LIST_STATES_EMPRESAS} />}
        end={
          <>
            <ButtonIcon variant="outlined" color="dark" noLink startIcon={<EditIcon />} handleClick={() => navigate(`${PRIVATE_ROUTES.EDIT_STATES_EMPRESAS}?id=${estado?._id}`)}>
              Editar
            </ButtonIcon>
          </>
        }
        titulo={"Editando estado"}
      />
      <EstadoEdit id={id} editting={false} resposeState={() => {}} updateState={() => {}} isEmpresa={true} />
      <br />
    </Container>
  );
};

export default EstadoEmpresasFichaTemplate;
