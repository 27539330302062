/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "../../../assets/css/vistaFormulario.css";
import { openFeedback } from "../../../store/slices/feedback";
import Loading from "../../elementos/Loading";

import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useUpdateEmpresaMutation } from "../../../store/api/endpoints/empresaApiSlice";
import { clean, getEmpresa } from "../../../store/slices/empresa";
import ListaDeAtributosEmpresa from "./FormEmpresa";

export const FormEmpresaEdit = ({ id, editting }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userStore = useSelector(getEmpresa, shallowEqual);

  const [updateEmpresa, responseupdateEmpresa] = useUpdateEmpresaMutation();

  useEffect(() => {
    if (responseupdateEmpresa.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha actualizado la empresa!",
        })
      );
      dispatch(clean());
      navigate(`${PRIVATE_ROUTES.FICHA_EMPRESA}?id=${id}`);
    }

    if (responseupdateEmpresa.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: responseupdateEmpresa?.error?.data?.message,
        })
      );
    }
  }, [responseupdateEmpresa.isSuccess, responseupdateEmpresa.isError]);

  const handleSubmit = async () => {
    updateEmpresa({ id, data: userStore });
  };

  if (responseupdateEmpresa.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <ListaDeAtributosEmpresa onSubmit={handleSubmit} editting={editting} />
      <br />
    </>
  );
};
