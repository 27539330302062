/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "../../../assets/css/vistaFormulario.css";
import { clenaAlone, getEstado } from "../../../store/slices/estado";
import Loading from "../../elementos/Loading";
import ListaDeAtributosEstado from "./FormEstado";

export const EstadoEdit = ({ id, editting, resposeState, updateState, isEmpresa = false }) => {
  const estado = useSelector(getEstado, shallowEqual);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    updateState({ id, datos: estado });
  };
  useEffect(() => {
    dispatch(clenaAlone());
  }, []);

  if (resposeState.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <ListaDeAtributosEstado type="crear" onSubmit={handleSubmit} editting={editting} isEmpresa={isEmpresa} />
      <br />
    </>
  );
};
