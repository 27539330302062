/* eslint-disable no-unused-vars */
import { CancelOutlined } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonIcon from "@mui/icons-material/Person";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { getListEmpresas } from "../../../store/slices/empresa";
import { alone, getUser, getUserEmpresas, setEmpresasAsociadas } from "../../../store/slices/user";
import { ButtonIcon, Divider, Input, PasswordInput } from "../../atoms";
import { CheckboxList } from "../../molecule";

const ListaDeAtributosClientes = ({ onSubmit, editting }) => {
  const userStorage = useSelector(getUser, shallowEqual);
  const empresasStorage = useSelector(getListEmpresas, shallowEqual);
  const empresasAsociadas = useSelector(getUserEmpresas, shallowEqual);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [errorPassword, seterrorPassword] = useState({
    error: false,
    message: "",
  });

  const id = searchParams.get("id");

  const handleCancel = useCallback(() => {
    let redirect = `${PRIVATE_ROUTES.LIST_USERS}`;
    if (id) {
      redirect = `${PRIVATE_ROUTES.FICHA_USER}?id=${id}`;
    }
    navigate(redirect);
  }, [id, navigate]);

  const changeValue = useCallback((value, key) => {
    if (key.includes("contrasena")) {
      validatePassword();
    }
    dispatch(alone({ [key]: value }));
  }, []);

  const addEmpresaComuna = (newEmpresa) => {
    dispatch(setEmpresasAsociadas(newEmpresa));
  };

  const validatePassword = () => {
    if (userStorage?.contrasena1 !== userStorage?.contrasena2 && userStorage?.contrasena1?.length > 0 && userStorage?.contrasena2?.length > 0) {
      seterrorPassword({
        error: true,
        message: "Las contraseñas son distintas.",
      });
    } else {
      seterrorPassword({
        error: false,
        message: "",
      });
    }
  };

  const handleSubmit = () => {
    if (!errorPassword.error) {
      onSubmit();
    }
  };

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Input
            label="Nombre"
            icon={<PersonIcon />}
            value={userStorage?.nombre ? userStorage?.nombre : ""}
            name="nombre"
            onChange={changeValue}
            type="text"
            restricciones={["alfanumerico"]}
            required
            disabled={!editting}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input label="Apellido" icon={<PersonIcon />} value={userStorage?.apellido} name="apellido" onChange={changeValue} type="text" restricciones={["alfanumerico"]} required disabled={!editting} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input label="Comuna" icon={<PersonIcon />} value={userStorage?.comuna ?? ""} name="comuna" onChange={changeValue} type="number" restricciones={["soloNumeros"]} min={0} max={30} required disabled={!editting} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Email"
            icon={<MailOutlineIcon />}
            value={userStorage?.email}
            name="email"
            onChange={changeValue}
            type="text"
            restricciones={["email"]}
            helperText="Ej correo@hotmail.com"
            required
            disabled={!editting}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="Telefono"
            icon={<WhatsAppIcon />}
            helperText="Ej: 5491112341234. Sin tel colocar 0."
            name="telefono"
            value={userStorage?.telefono}
            onChange={changeValue}
            disabled={!editting}
            type="tel"
            restricciones={["soloTelefono"]}
            required
          />
        </Grid>
        {editting && (
          <Grid item xs={12} md={4}>
            <PasswordInput text="Contraseña" password={userStorage?.contrasena} handleChange={changeValue} name={"contrasena"} disabled={!editting} />
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <CheckboxList elegidos={empresasAsociadas} titulo="Seleccione empresa" opciones={empresasStorage} handleChange={addEmpresaComuna} name="empresas_asociadas" disabled={!editting} />
        </Grid>
        {errorPassword.error && (
          <Grid item xs={12} md={4}>
            <h3 style={{ color: "#d33939" }}>{errorPassword.message}</h3>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      {editting && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ButtonIcon type="button" variant="contained" color="error" noLink handleClick={handleCancel} startIcon={<CancelOutlined />}>
              Cancelar
            </ButtonIcon>
            <ButtonIcon variant="contained" color="success" noLink startIcon={<CheckIcon />} handleClick={handleSubmit} styleButton={{ marginLeft: "10px" }}>
              Guardar
            </ButtonIcon>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

export default ListaDeAtributosClientes;
