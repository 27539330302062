import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import imageCompression from "browser-image-compression";
import { React, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useUploadFileMutation } from "../../../store/api/endpoints/uploadApiSlice";
import { Loading } from "../../molecule";
import "./Dropzone.css";

export default function Dropzone(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [mensajeError, setMensajeError] = useState("");
  const [uploadFile, { data: responseUploadFile, isUninitialized: isUninitializedUploadFile, isLoading: isLoadingUploadFile, isSuccess: isSuccessUploadFile, isError: isErrorUploadFile, error }] = useUploadFileMutation();
  const dropzone_messages = {
    "file-too-large": "El archivo es muy pesado. Excede los " + props.maxSize / 1000 + "kb.",
    "file-invalid-type": "El formato del archivo es inválido. Formatos permitidos: " + props.formatosPermitidos,
    "too-many-files": "Demasiados archivos elegidos. Máximos archivos permitidos: " + props.maxFiles,
  };

  async function handleImageUpload(files) {
    const imagesFiles = files;
    // const formato = imageFile.name.split(".")[imageFile.name.split(".").length - 1];
    //console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    //console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    try {
      for (let index = 0; index < imagesFiles.length; index++) {
        const options = {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 1080,
          useWebWorker: true,
          fileType: imagesFiles[index].type,
        };
        const filename = Date.now();
        const compressedFile = await imageCompression(imagesFiles[index], options);
        //console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        let body = new FormData();
        body.append("file", compressedFile);

        await uploadFile({
          url: props.url + `/${filename}`,
          body,
        });
        //Uplaod image in baja definition
        const optionsBaja = {
          maxSizeMB: process.env.REACT_APP_maxSizeMB,
          maxWidthOrHeight: process.env.REACT_APP_maxWidthOrHeight,
          useWebWorker: true,
          fileType: imagesFiles[index].type,
        };
        const compressedFileBaja = await imageCompression(imagesFiles[index], optionsBaja);
        //console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        let bodyBaja = new FormData();
        bodyBaja.append("file", compressedFileBaja);
        await uploadFile({
          url: props.url + `/${filename}_baja`,
          body: bodyBaja,
        });
      }
      //await uploadToServer(compressedFile); // write your own logic
    } catch (error) {
      console.log(error);
    }
  }

  const handleOnDropAccepted = (files) => {
    handleImageUpload(files);
  };

  const handleOnDropRejected = (e) => {
    //muestra el codigo de error por consola cuando rechaza un archivo
    //console.log(e[0].errors[0].code);
    setMensajeError(dropzone_messages[e[0].errors[0].code]);
    handleDialogOpen();
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: props.formatosPermitidos,
    maxFiles: props.maxFiles,
    maxSize: props.maxSize,
    onDropAccepted: handleOnDropAccepted,
    onDropRejected: handleOnDropRejected,
  });

  useEffect(() => {
    if (isSuccessUploadFile) {
      let imagenesActuales = [...props.imagenesActuales];
      if (!responseUploadFile?.ruta.includes("_baja")) {
        imagenesActuales.push(responseUploadFile?.ruta);
        props.handleUpload(props.name, imagenesActuales);
      }
    }

    if (isErrorUploadFile) {
      setMensajeError(`Error al cargar la imágen. Intenta nuevamente por favor. ${error}`);
      handleDialogOpen();
    }
  }, [isSuccessUploadFile, isErrorUploadFile]);

  if (isLoadingUploadFile) return <Loading />;

  return (
    <section className="container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <AddAPhotoIcon sx={{ fontSize: 40 }} />
        <br />
        Cargar imagen.
        {props.maxFiles && (
          <span>
            <b style={{ fontSize: "smaller" }}>(Cantidad maxima {props.maxFiles})</b>
          </span>
        )}
      </div>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <Alert severity="error">{mensajeError}</Alert>
      </Dialog>
    </section>
  );
}
