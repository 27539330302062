/* eslint-disable no-unused-vars */
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Divider, List, ListItem, ListItemText, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useGetSucesoMutation } from "../../../store/api/endpoints/sucesosApiSlice";
import { useGetTiposEspecieArbolMutation, useUploadPartialMutation } from "../../../store/api/endpoints/tipoEspecieArbolApiSlice";
import { useGetTipoNombreCallesMutation } from "../../../store/api/endpoints/tipoNombreCalleApiSlice";
import { getListEmpresas } from "../../../store/slices/empresa";
import { getListEstados } from "../../../store/slices/estado";
import { openFeedback } from "../../../store/slices/feedback";
import { alone, aloneComuna, getSuceso, updateImagesComuna } from "../../../store/slices/sucesos";
import { getListTipoCortes } from "../../../store/slices/tipoCortes";
import { getListTipoDanio } from "../../../store/slices/tipoDanio";
import { getListTipoIncidente } from "../../../store/slices/tipoIncidentes";
import { convertDateUtc } from "../../../utils/formatDate";
import { ButtonIcon } from "../../atoms";
import { Loading, SmallInput } from "../../molecule";
import SelectSmall from "../../molecule/SelectSmall";
import AutocompleteList from "../AutocompleteInput";
import { ListImages } from "../ListImages";
import { style } from "./styles";

const ListaDeAtributosSuceso = ({ id }) => {
  const debounceTimeout = useRef(null);
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const [editaEmpresa, setEditaEmpresa] = useState(false);
  const suceso = useSelector(getSuceso, shallowEqual);
  const states = useSelector(getListEstados, shallowEqual);
  const incidencias = useSelector(getListTipoIncidente, shallowEqual);
  const danios = useSelector(getListTipoDanio, shallowEqual);
  const [getSucesoAlone, responseSuceso] = useGetSucesoMutation();
  const cortes = useSelector(getListTipoCortes, shallowEqual);
  const empresas = useSelector(getListEmpresas, shallowEqual);
  const [getCalles, responseCalles] = useGetTipoNombreCallesMutation();
  const [getEspecieArbol, responseEspecieArbol] = useGetTiposEspecieArbolMutation();
  const [updatePartial, responseUpdatePartial] = useUploadPartialMutation();

  const handleUbication = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const geolocalizacion = [position.coords.latitude, position.coords.longitude];
          dispatch(aloneComuna({ ["geolocalizacion"]: geolocalizacion }));
          updatePartial({ campo: "datos_comuna.geolocalizacion", valor: geolocalizacion, id: suceso._id });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const onChange = (value, key) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    const attribute = key?.split(".")[1];
    if (attribute !== "especie_arbol" && attribute !== "calle" && attribute !== "estado") {
      dispatch(aloneComuna({ [attribute]: value }));
    }
    if (key === "estado") {
      const selectedState = states.find((state) => state.key === value);
      setEditaEmpresa(selectedState?.elige_empresa || false);
      dispatch(alone({ estado: value }));
    }
    if (attribute === "empresa_asignada") {
      dispatch(
        aloneComuna({
          empresa_asignada: {
            _id: value,
          },
        })
      );
    }
    debounceTimeout.current = setTimeout(() => {
      setisLoading(true);
      updatePartial({ campo: key, valor: value, id: suceso._id });
    }, 1000);
  };

  const onChangeAutoComplete = (value, key) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    const attribute = key?.split(".")[1];
    dispatch(
      aloneComuna({
        [attribute]: value,
      })
    );
    const idValue = value.key;
    debounceTimeout.current = setTimeout(() => {
      setisLoading(true);
      updatePartial({ campo: key, valor: idValue, id: suceso._id });
    }, 1000);
  };

  const onUploadImage = (key, value) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    dispatch(updateImagesComuna(value));
    debounceTimeout.current = setTimeout(() => {
      updatePartial({ campo: key, valor: value, id: suceso._id });
    }, 1000);
  };

  useEffect(() => {
    if (responseUpdatePartial.isSuccess) {
      setisLoading(false);
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha actualizado el registro!",
        })
      );
      getSucesoAlone({ id, isFicha: false });
    }
    if (responseUpdatePartial.error) {
      setisLoading(false);
      dispatch(
        openFeedback({
          severity: "error",
          message: "¡No se pudo actualizar el registro!",
        })
      );
    }
  }, [responseUpdatePartial.isSuccess, responseUpdatePartial.error]);

  useEffect(() => {
    if (suceso?.estado) {
      const selectedState = states.find((state) => state.key === suceso?.estado);

      setEditaEmpresa(selectedState?.elige_empresa || false);
    }
  }, [suceso?.estado]);

  useEffect(() => {
    if (responseSuceso.isSuccess) {
      let sucesoFormatted = JSON.parse(JSON.stringify(responseSuceso.data.data));
      const nuevaFecha = convertDateUtc(sucesoFormatted?.fechahora);
      const splitFecha = nuevaFecha.split(" ");
      sucesoFormatted.fecha = splitFecha[0];
      sucesoFormatted.hora = splitFecha[1];
      dispatch(alone(sucesoFormatted));
    }
  }, [responseSuceso.isSuccess]);

  if (isLoading || responseSuceso.isLoading) return <Loading />;

  return (
    <List sx={style} aria-label="mailbox folders">
      <ListItem>
        <ListItemText sx={{ display: "flex", justifyContent: "center", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700, fontSize: "1.2rem" }}>Informe Comuna</Typography>} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Calle:</Typography>} />
        <AutocompleteList
          label={"Calles"}
          slice={getCalles}
          handleChange={onChangeAutoComplete}
          name={"datos_comuna.calle"}
          response={responseCalles}
          value={suceso.datos_comuna.calle}
          defaultValue={{ label: "Sin seleccionar", key: "0" }}
        />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Chapa:</Typography>} />
        <SmallInput restricciones={["soloNumeros"]} value={suceso?.datos_comuna?.chapa ?? ""} onChange={onChange} name={"datos_comuna.chapa"} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Referencia:</Typography>} />
        <SmallInput restricciones={["alfaNumerico"]} value={suceso?.datos_comuna?.referencia ?? ""} onChange={onChange} name={"datos_comuna.referencia"} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Geolocalizacion:</Typography>} />
        <SmallInput restricciones={["alfaNumerico"]} value={suceso?.datos_comuna?.geolocalizacion ?? ""} onChange={onChange} name={"datos_comuna.geolocalizacion"} />
        <ButtonIcon endIcon={<LocationOnOutlinedIcon fontSize="20px" />} styleButton={{ marginLeft: "15px" }} noLink handleClick={handleUbication} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Especie Árbol:</Typography>} />
        <AutocompleteList
          label={"Especie arbol"}
          slice={getEspecieArbol}
          handleChange={onChangeAutoComplete}
          name={"datos_comuna.especie_arbol"}
          response={responseEspecieArbol}
          value={suceso.datos_comuna.especie_arbol}
          defaultValue={{ label: "Sin seleccionar", key: "0" }}
        />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>DAP (cm):</Typography>} />
        <SmallInput restricciones={["soloNumeros"]} value={suceso?.datos_comuna?.dap ?? ""} onChange={onChange} name={"datos_comuna.dap"} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Altura(m):</Typography>} />
        <SmallInput restricciones={["soloNumeros"]} value={suceso?.datos_comuna?.altura ?? ""} onChange={onChange} name={"datos_comuna.altura"} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Daño:</Typography>} />
        <SelectSmall lista={danios} value={suceso?.datos_comuna?.tipoDanio ?? ""} name={"datos_comuna.tipoDanio"} onChange={onChange} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Incidencia:</Typography>} />
        <SelectSmall lista={incidencias} value={suceso?.datos_comuna?.tipoIncidencia ?? ""} name={"datos_comuna.tipoIncidencia"} onChange={onChange} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Estado:</Typography>} />
        <SelectSmall lista={states} value={suceso?.estado ?? ""} name={"estado"} onChange={onChange} />
      </ListItem>
      {editaEmpresa ? (
        <>
          <Divider component="li" />
          <ListItem>
            <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Seleccionar empresa:</Typography>} />
            <SelectSmall lista={empresas || []} value={suceso?.datos_comuna?.empresa_asignada?._id ?? []} name={"datos_comuna.empresa_asignada"} onChange={onChange} />
          </ListItem>
        </>
      ) : null}
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Corte de calle:</Typography>} />
        <SelectSmall lista={cortes} value={suceso?.datos_comuna?.corte_de_calle ?? ""} name={"datos_comuna.corte_de_calle"} onChange={onChange} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Observaciones:</Typography>} />
        <SmallInput restricciones={["alfaNumerico"]} multiline value={suceso?.datos_comuna?.observaciones ?? ""} onChange={onChange} name={"datos_comuna.observaciones"} />
      </ListItem>
      <Divider component="li" />
      <ListItem sx={{ height: "100%", minHeight: "71px" }}>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Fotos de comuna:</Typography>} />
        <ListImages imagenes={suceso?.datos_comuna?.fotos_comuna} ruta="datos_comuna.fotos_comuna" handleChange={onUploadImage} editable={true} />
      </ListItem>
    </List>
  );
};

export default ListaDeAtributosSuceso;
