/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import * as React from "react";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { useRemoveFileMutation } from "../../../store/api/endpoints/uploadApiSlice";
import { openFeedback } from "../../../store/slices/feedback";
import { formatosPermitidos, maxSize } from "../../../utils/global";
import { Loading } from "../../molecule";
import "./index.css";
import { DropZone } from "../DropZone";
import { MaximizeImage } from "../maximizeImage";

export default function ListImages(props) {
  const cantidadMaximaASubir = 5;
  const dispatch = useDispatch();
  const [imagenes, setImagenes] = React.useState([...props.imagenes]);
  const [deleteImage, responseDelete] = useRemoveFileMutation();
  const [isOpenImage, setIsOpenImage] = useState(false);
  const [imagen, setimagen] = useState({});
  const handleDelete = async (data) => {
    const image = data.split(".com")[1];
    let ruta = image.split("/")[1] + "/" + image.split("/")[2];
    const filename = image.split("/")[3];
    await deleteImage({ filename, ruta });
  };

  const handleClickOpen = (item, index) => {
    setimagen({
      src: item,
      index,
    });
    setIsOpenImage(true);
  };

  const ChangeMaximizeImage = (action) => {
    const largoImagenes = imagenes.length;
    const actualPosition = imagen.index;
    let newIndex;
    if (action === "back" && actualPosition > 0) {
      newIndex = actualPosition - 1;
      setimagen({ src: imagenes[newIndex], index: newIndex });
    } else if (action === "next" && actualPosition < largoImagenes - 1) {
      newIndex = actualPosition + 1;
      setimagen({ src: imagenes[newIndex], index: newIndex });
    }
  };

  React.useEffect(() => {
    setImagenes([...props.imagenes]);
  }, [props.imagenes]);

  React.useEffect(() => {
    if (responseDelete.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: "No se pudo eliminar la imagen.",
        })
      );
    }
    if (responseDelete.isSuccess) {
      const imagenesCopy = [...imagenes];
      imagenesCopy.splice(imagen.index, 1);
      props.handleChange(props.ruta, imagenesCopy);
      setIsOpenImage(false);
      dispatch(
        openFeedback({
          severity: "success",
          message: "Imagen eliminada.",
        })
      );
    }
  }, [responseDelete.isError, responseDelete.isSuccess]);

  if (responseDelete.isLoading) {
    return <Loading />;
  }

  return (
    <Grid container spacing={2}>
      {imagenes.length > 0 &&
        imagenes?.map((item, index) => (
          <Grid item xs={12} md={3} key={index} sx={{ display: "flex", justifyContent: "center" }}>
            <div className="imagen-con-x">
              <div onClick={() => handleClickOpen(item, index)}>
                <img
                  src={item}
                  onError={(e) => {
                    e.currentTarget.src = "";
                    e.currentTarget.alt = "Error al cargar la imagen";
                  }}
                  alt={`Imagen informe ${item.split("/informes/")[1]}`}
                  style={{ width: "10rem", height: "10rem" }}
                />
              </div>
            </div>
          </Grid>
        ))}
      {imagenes.length < cantidadMaximaASubir && props.editable && (
        <Grid item xs={12} md={3}>
          <DropZone
            url={"upload/image/tormentas"}
            returnUrl={"/ruta-actual/id-informe"}
            formatosPermitidos={formatosPermitidos}
            name={props.ruta}
            maxSize={maxSize}
            maxFiles={cantidadMaximaASubir}
            imagenesActuales={imagenes}
            handleUpload={props.handleChange}
          />
          <br />
        </Grid>
      )}
      {isOpenImage && <MaximizeImage imagen={imagen} ChangeMaximizeImage={ChangeMaximizeImage} setIsOpenImage={setIsOpenImage} isOpenImage={isOpenImage} handleDelete={handleDelete} editable={props.editable} />}
    </Grid>
  );
}
