/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "../../../assets/css/vistaFormulario.css";
import { openFeedback } from "../../../store/slices/feedback";
import Loading from "../../elementos/Loading";

import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useCreateTipoIncidenteMutation } from "../../../store/api/endpoints/tipoIncidenteApiSlice";
import { useCreateTipoIncidenteEmpresaMutation } from "../../../store/api/endpoints/tipoIncidenteEmpresasApiSlice";

import { clean } from "../../../store/slices/tipoIncidentes";
import { gettipoIncidente } from "../../../store/slices/tipoIncidentes";
import FormIncidencia from "./FormIncidencia";

export const IncidenciaNueva = ({ isEmpresa = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirect = isEmpresa ? PRIVATE_ROUTES.LIST_INCIDENT_EMPRESAS : PRIVATE_ROUTES.LIST_INCIDENT;
  const incidencia = useSelector(gettipoIncidente, shallowEqual);

  const [createIncidencia, responseIncidencia] = useCreateTipoIncidenteMutation();
  const [createIncidenciaEmpresa, responseIncidenciaEmpresa] = useCreateTipoIncidenteEmpresaMutation();

  useEffect(() => {
    if (responseIncidenciaEmpresa.isSuccess || responseIncidencia.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha actualizado el Incidencia!",
        })
      );
      dispatch(clean());
      navigate(redirect);
    }
    if (responseIncidenciaEmpresa.isError || responseIncidencia.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: responseIncidenciaEmpresa?.error?.data?.message ?? responseIncidencia?.error?.data?.message,
        })
      );
    }
  }, [responseIncidenciaEmpresa.isSuccess, responseIncidenciaEmpresa.isError, responseIncidencia.isSuccess, responseIncidencia.isError]);

  useEffect(() => {
    dispatch(clean());
  }, []);

  const handleSubmit = async () => {
    if (isEmpresa) await createIncidenciaEmpresa(incidencia);
    else await createIncidencia(incidencia);
  };

  if (responseIncidencia.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <FormIncidencia onSubmit={handleSubmit} editting={true} />
      <br />
    </>
  );
};
