/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "../../../assets/css/vistaFormulario.css";
import { openFeedback } from "../../../store/slices/feedback";
import Loading from "../../elementos/Loading";

import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useCreateEmpresaMutation } from "../../../store/api/endpoints/empresaApiSlice";
import { getEmpresa } from "../../../store/slices/empresa";
import ListaDeAtributosClientes from "./FormEmpresa";

export const EmpresaNuevo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const empresa = useSelector(getEmpresa, shallowEqual);

  const [createEmpresa, responseCreate] = useCreateEmpresaMutation();

  useEffect(() => {
    if (responseCreate.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha creado la empresa!",
        })
      );
      navigate(PRIVATE_ROUTES.LIST_EMPRESA);
    }

    if (responseCreate.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: responseCreate?.error?.data?.message,
        })
      );
    }
  }, [responseCreate.isSuccess, responseCreate.isError]);

  const handleSubmit = async () => {
    createEmpresa(empresa);
  };

  if (responseCreate.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <ListaDeAtributosClientes onSubmit={handleSubmit} editting={true} />
      <br />
    </>
  );
};
