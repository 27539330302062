import { Divider, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";
import { style } from "./styles";
import { shallowEqual, useSelector } from "react-redux";
import { getListEmpresas } from "../../../store/slices/empresa";
const ListDataUser = ({ user }) => {
  const empresasStorage = useSelector(getListEmpresas, shallowEqual);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <h3 style={{ textAlign: "center" }}>Datos comuna</h3>
        <List sx={style} aria-label="mailbox folders">
          <Grid item xs={12} md={12}>
            <ListItem>
              <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Nombre:</Typography>} />
              <ListItemText>{user?.nombre}</ListItemText>
            </ListItem>
            <Divider component="li" />
            <ListItem>
              <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Apellido:</Typography>} />
              <ListItemText>{user?.apellido}</ListItemText>
            </ListItem>
            <Divider component="li" />
            <ListItem>
              <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Comuna:</Typography>} />
              <ListItemText>{user?.comuna}</ListItemText>
            </ListItem>
            <Divider component="li" />
            <ListItem>
              <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Email:</Typography>} />
              <ListItemText>{user?.email}</ListItemText>
            </ListItem>
            <Divider component="li" />
            <ListItem>
              <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Telefono:</Typography>} />
              <ListItemText>{user?.telefono}</ListItemText>
            </ListItem>
          </Grid>
        </List>
      </Grid>
      <Grid item xs={12} md={6}>
        <h3 style={{ textAlign: "center" }}>Empresas asociadas</h3>
        <List sx={style} aria-label="mailbox folders">
          <Grid item xs={12} md={12}>
            {empresasStorage?.map((el, index) => (
              <div key={index}>
                {index !== 0 && <Divider component="li" />}
                <ListItem>
                  <ListItemText>{el.nombre}</ListItemText>
                </ListItem>
              </div>
            ))}
          </Grid>
        </List>
      </Grid>
    </Grid>
  );
};

export default ListDataUser;
