import { createSlice } from "@reduxjs/toolkit";

const initAlone = { email: "", nombre: "", apellido: "", telefono: "", contrasena: "" };
const paginationInfo = {
  hasNextPage: null,
  hasPrevPage: null,
  limit: 10,
  nextPage: 0,
  page: 1,
  pagingCounter: 0,
  prevPage: null,
  totalDocs: 0,
  totalPages: 0,
};
export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    list: [],
    alone: initAlone,
    pagination: paginationInfo,
  },
  reducers: {
    alone: (state, action) => {
      state.alone = {
        ...state.alone,
        ...action.payload,
      };
    },
    list: (state, action) => {
      state.list = action.payload;
    },
    clean: (state, action) => {
      state.alone = initAlone;
      state.pagination = paginationInfo;
    },
    setPagination: (state, action) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
    },
  },
});

export const { alone, list, clean, setPagination } = adminSlice.actions;

export const getListAdmin = (state) => state.admin.list;
export const getAdmin = (state) => state.admin.alone;
export const getPagination = (state) => state.admin.pagination;
