import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment-timezone";
import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useGetSucesoMutation } from "../../../store/api/endpoints/sucesosApiSlice";
import { useUploadPartialMutation } from "../../../store/api/endpoints/tipoEspecieArbolApiSlice";
import { openFeedback } from "../../../store/slices/feedback";
import { alone, getSuceso } from "../../../store/slices/sucesos";
import { convertDateUtc } from "../../../utils/formatDate";
import Loading from "../../elementos/Loading";
import { DatePickerCustom, SmallInput, TimePickerCustom } from "../../molecule";
import { style } from "./styles";

const FormSucesoCucc = React.memo(({}) => {
  const debounceTimeout = React.useRef(null);
  const dispatch = useDispatch();
  const [getSucesoAlone, responseSuceso] = useGetSucesoMutation();
  const [searchParams] = useSearchParams();
  const suceso = useSelector(getSuceso, shallowEqual);
  const [updatePartial, responseUpdatePartial] = useUploadPartialMutation();
  const id = searchParams.get("id");

  const onChange = (value, key) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    if (key === "fecha" || key === "hora") {
      dispatch(alone({ [key]: value }));
      debounceTimeout.current = setTimeout(() => {
        updatePartial({ campo: "fechahora", valor: moment.tz(`${suceso.fecha} ${suceso.hora}`, "DD-MM-YYYY HH:mm:ss", "America/Argentina/Buenos_Aires").format(), id });
      }, 1000);
    } else {
      dispatch(alone({ [key]: value }));
      debounceTimeout.current = setTimeout(() => {
        updatePartial({ campo: key, valor: value, id });
      }, 1000);
    }
  };

  const onChangeFechaHora = (fechaTemp, horaTemp) => {
    if (fechaTemp) {
      dispatch(alone({ ["fecha"]: fechaTemp }));
      const newDate = moment.tz(`${fechaTemp} ${suceso?.hora}`, "DD-MM-YYYY HH:mm:ss", "America/Argentina/Buenos_Aires").format();

      debounceTimeout.current = setTimeout(() => {
        updatePartial({ campo: "fechahora", valor: newDate, id });
      }, 1000);
    }
    if (horaTemp) {
      dispatch(alone({ ["hora"]: horaTemp }));
      const newDate = moment.tz(`${suceso?.fecha} ${horaTemp}`, "DD-MM-YYYY HH:mm:ss", "America/Argentina/Buenos_Aires").format();
      debounceTimeout.current = setTimeout(() => {
        updatePartial({ campo: "fechahora", valor: newDate, id });
      }, 1000);
    }
  };

  React.useEffect(() => {
    if (responseUpdatePartial.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha actualizado el registro!",
        })
      );
      getSucesoAlone({ id, isFicha: false });
    }
    if (responseUpdatePartial.error) {
      dispatch(
        openFeedback({
          severity: "error",
          message: "¡No se pudo actualizar el registro!",
        })
      );
    }
  }, [responseUpdatePartial.isSuccess, responseUpdatePartial.error]);

  if (responseUpdatePartial.isLoading || responseSuceso.isLoading) return <Loading />;

  return (
    <List sx={style} aria-label="mailbox folders">
      <ListItem>
        <ListItemText sx={{ display: "flex", justifyContent: "center", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700, fontSize: "1.2rem" }}>Informe General</Typography>} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>ID:</Typography>} />
        <SmallInput restricciones={["alfaNumerico"]} value={suceso?.identificador} onChange={onChange} name={"identificador"} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Fecha Llamado:</Typography>} />
        <DatePickerCustom value={suceso?.fecha} handleChange={(e) => onChangeFechaHora(e, false)} name="fecha" seterror={() => {}} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Hora llamado:</Typography>} />
        <TimePickerCustom value={suceso?.hora} handleChange={(e) => onChangeFechaHora(false, e)} name="hora" seterror={() => {}} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Domicilio:</Typography>} />
        <SmallInput restricciones={["alfaNumerico"]} value={suceso?.domicilio} onChange={onChange} name={"domicilio"} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Comuna:</Typography>} />
        <SmallInput restricciones={["alfaNumerico"]} value={suceso?.comuna} onChange={onChange} name={"comuna"} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Telefono vecino:</Typography>} />
        <SmallInput restricciones={["alfaNumerico"]} value={suceso?.telefono_vecino} onChange={onChange} name={"telefono_vecino"} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Ingrese el relato.:</Typography>} />
        <SmallInput restricciones={["alfaNumerico"]} value={suceso?.relato} onChange={onChange} name={"relato"} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Ingrese el origen:</Typography>} />
        <SmallInput restricciones={["alfaNumerico"]} value={suceso?.origen} onChange={onChange} name={"origen"} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Tipo:</Typography>} />
        <SmallInput restricciones={["alfaNumerico"]} value={suceso?.tipo} onChange={onChange} name={"tipo"} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Subtipo:</Typography>} />
        <SmallInput restricciones={["alfaNumerico"]} value={suceso?.subtipo} onChange={onChange} name={"subtipo"} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Fecha ingreso CUCC:</Typography>} />
        <ListItemText>{convertDateUtc(suceso?.carga_fechahora)}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Fecha Ult. Modif.:</Typography>} />
        <ListItemText>{convertDateUtc(suceso?.fecha_ultmodif)}</ListItemText>
      </ListItem>
    </List>
  );
});

export default FormSucesoCucc;
