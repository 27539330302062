/* eslint-disable no-unused-vars */
import { CancelOutlined } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import PersonIcon from "@mui/icons-material/Person";
import { Grid, ListItemText, Switch, Typography } from "@mui/material";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { alone, getEstado } from "../../../store/slices/estado";
import { ButtonIcon, Divider, Input } from "../../atoms/";
import { SketchPicker } from "react-color";
import { SmallInput } from "../../molecule";
import PaletteOutlinedIcon from "@mui/icons-material/PaletteOutlined";

const ListaDeAtributosEstado = ({ onSubmit, editting, isEmpresa }) => {
  const estado = useSelector(getEstado, shallowEqual);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [openColorModal, setOpenColorModal] = useState(false);
  const navigate = useNavigate();
  const id = searchParams.get("id");

  const handleCancel = () => {
    const redirect = isEmpresa ? (id ? `${PRIVATE_ROUTES.FICHA_STATE_EMPRESAS}?id=${id}` : PRIVATE_ROUTES.LIST_STATES_EMPRESAS) : id ? `${PRIVATE_ROUTES.FICHA_STATE}?id=${id}` : PRIVATE_ROUTES.LIST_STATES;

    navigate(redirect);
  };

  const changeValue = (value, key) => {
    dispatch(alone({ [key]: value }));
  };

  const handleSave = () => {
    onSubmit();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} sx={{ justifyContent: "center" }}>
        <Grid item xs={12} md={6}>
          <Input label="Nombre" icon={<PersonIcon />} value={estado?.nombre || ""} name="nombre" onChange={changeValue} type="text" restricciones={["alfanumerico"]} required disabled={!editting} />
        </Grid>

        {isEmpresa ? (
          <Grid item xs={12} md={6}>
            <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Vuelve a la comuna:</Typography>} />
            <Switch disabled={!editting} checked={estado?.vuelve_a_comuna ?? false} onChange={(e) => changeValue(e.target.checked, "vuelve_a_comuna")} inputProps={{ "aria-label": "controlled" }} />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={6}>
              <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Elige empresa:</Typography>} />
              <Switch disabled={!editting} checked={estado?.elige_empresa ?? false} onChange={(e) => changeValue(e.target.checked, "elige_empresa")} inputProps={{ "aria-label": "controlled" }} />
            </Grid>

            <Grid item xs={12} md={6}>
              <ListItemText
                sx={{ flex: "none", paddingRight: "10px" }}
                primary={
                  <div style={{ gap: "16px", display: "flex", alignItems: "center" }}>
                    <ButtonIcon type="button" variant="outlined" disabled={!editting} noLink handleClick={() => setOpenColorModal(!openColorModal)} startIcon={<PaletteOutlinedIcon />} />
                    <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                      <span
                        style={{
                          backgroundColor: estado.color || "black",
                          width: "17px",
                          height: "17px",
                          borderRadius: "50%",
                        }}
                      ></span>
                      <span style={{ fontSize: "1.2rem" }}>
                        Color Hexadecimal: <b>{estado.color}</b>
                      </span>
                    </div>
                  </div>
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<span style={{ fontWeight: 700 }}>Estado final:</span>} />
              <Switch disabled={!editting} checked={estado?.estado_final ?? false} onChange={(e) => changeValue(e.target.checked, "estado_final")} inputProps={{ "aria-label": "controlled" }} />
            </Grid>
          </>
        )}
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      {editting && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ButtonIcon type="button" variant="contained" color="error" noLink handleClick={handleCancel} startIcon={<CancelOutlined />}>
              Cancelar
            </ButtonIcon>
            <ButtonIcon variant="contained" color="success" noLink startIcon={<CheckIcon />} handleClick={handleSave} styleButton={{ marginLeft: "10px" }}>
              Guardar
            </ButtonIcon>
          </Grid>
        </Grid>
      )}

      {openColorModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
          onClick={() => setOpenColorModal(false)}
        >
          <div
            style={{
              borderRadius: "8px",
              width: "300px",
              height: "420px",
              padding: "10px",
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              overflow: "hidden",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              style={{
                flex: 1,
                height: "100%",
                width: "95%",
              }}
            >
              <SketchPicker
                color={estado.color}
                onChangeComplete={(e) => {
                  changeValue(e.hex, "color");
                }}
                width="100%"
              />
            </div>

            <ButtonIcon type="button" color="success" variant="contained" noLink handleClick={() => setOpenColorModal(false)} startIcon={<CheckIcon />}>
              Guardar color
            </ButtonIcon>
          </div>
        </div>
      )}
    </form>
  );
};

export default ListaDeAtributosEstado;
