import EditIcon from "@mui/icons-material/Edit";
import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useGetEmpresasMutation } from "../../../store/api/endpoints/empresaApiSlice";
import { useGetUserMutation, useGetUsersMutation } from "../../../store/api/endpoints/userApiSlice";
import { setList } from "../../../store/slices/empresa";
import { alone, clean } from "../../../store/slices/user";
import { ButtonIcon } from "../../atoms";
import { Loading, Volver } from "../../molecule";
import { HeaderVista } from "../../organism";
import ListDataUser from "../../organism/user/ListDataUser";

const UserFichaTemplate = () => {
  const dispatch = useDispatch();
  const [user, setuser] = useState({});
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [getCliente, reponseGetCliente] = useGetUserMutation();
  const [getClientes, reponseGetClientes] = useGetUsersMutation();
  const id = searchParams.get("id");

  useEffect(() => {
    if (reponseGetCliente.isSuccess) {
      setuser(reponseGetCliente?.data?.data);
      getClientes({ comunas: reponseGetCliente?.data?.data.comuna });
      dispatch(alone(reponseGetCliente?.data?.data));
    }
  }, [reponseGetCliente.isSuccess]);

  useEffect(() => {
    if (reponseGetClientes.isSuccess) {
      dispatch(setList(reponseGetClientes?.data?.data?.docs[0]?.empresas_asociadas));
    }
  }, [reponseGetClientes.isSuccess]);

  useEffect(() => {
    getCliente(id);
  }, []);

  if (reponseGetCliente.isLoading || reponseGetClientes.isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <HeaderVista
        start={<Volver label="Volver" to={PRIVATE_ROUTES.LIST_USERS} />}
        titulo={"Ficha de " + user?.nombre + " " + user?.apellido}
        end={
          <>
            <ButtonIcon
              variant="outlined"
              color="dark"
              noLink
              startIcon={<EditIcon />}
              handleClick={() => {
                dispatch(clean());
                navigate(`${PRIVATE_ROUTES.EDIT_USER}?id=${user?._id}`);
              }}
            >
              Editar
            </ButtonIcon>
          </>
        }
      />
      <ListDataUser user={user} />
      <br />
    </Container>
  );
};

export default UserFichaTemplate;
