import AddIcon from "@mui/icons-material/Add";
import { Container } from "@mui/material";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { columnsGeneral } from "../../../constants/TitleTables";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useGetTipoCortesEmpresasMutation } from "../../../store/api/endpoints/tipocortesEmpresasApiSlice";
import { alone, getListTipoCortesEmpresa, getPagination, list, setPagination } from "../../../store/slices/tipoCortesEmpresa";
import { ButtonIcon } from "../../atoms";
import { DataTable, Loading, TableRow } from "../../molecule";
import { HeaderVista } from "../../organism";

const CorteDeCalleEmpresasTemplate = () => {
  const dispatch = useDispatch();
  const [getCortes, { isLoading, isSuccess, data: dataTipoDeCortes }] = useGetTipoCortesEmpresasMutation();
  const storePage = useSelector(getPagination, shallowEqual);
  const listCortes = useSelector(getListTipoCortesEmpresa, shallowEqual);
  useEffect(() => {
    if (isSuccess) {
      const data = dataTipoDeCortes.data;
      dispatch(list(data.docs));
      dispatch(setPagination(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    dispatch(alone());
  }, []);

  useEffect(() => {
    let newPagination = {
      limit: storePage.limit === 0 ? 50 : storePage.limit,
      page: storePage.page,
    };
    getCortes({ ...newPagination });
  }, [storePage.page, storePage.limit]);

  if (isLoading) return <Loading />;

  return (
    <Container maxWidth="lg">
      <HeaderVista
        end={<ButtonIcon variant="outlined" size="small" color="primary" startIcon={<AddIcon />} to={PRIVATE_ROUTES.NEW_CORTE_DE_CALLE_EMPRESAS} children="Nuevo" />}
        titulo="Tipos de cortes de calles empresas"
      />
      <DataTable columns={columnsGeneral} rows={listCortes} RowComponent={TableRow} pagination={storePage} handlePagination={setPagination} label="Datos" to={`${PRIVATE_ROUTES.FICHA_CORTE_DE_CALLE_EMPRESAS}`} />
      <br />
    </Container>
  );
};

export default CorteDeCalleEmpresasTemplate;
