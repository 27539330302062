import EditIcon from "@mui/icons-material/Edit";
import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ButtonIcon } from "../../components/atoms";
import { Loading, Volver } from "../../components/molecule";
import { HeaderVista, ListDatosEmpresa } from "../../components/organism";
import { PRIVATE_ROUTES } from "../../routes/name-path.routes";
import { useGetEmpresaMutation } from "../../store/api/endpoints/empresaApiSlice";
import { useGetUsersMutation } from "../../store/api/endpoints/userApiSlice";
import { alone } from "../../store/slices/empresa";

const EmpresaFichaScreen = () => {
  const dispatch = useDispatch();
  const [empresa, setempresa] = useState({});
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [getEmpresa, reponsegetEmpresa] = useGetEmpresaMutation();
  const [getUsersComuna, responseUsersComuna] = useGetUsersMutation();
  const [comunasAsociadas, setcomunasAsociadas] = useState();

  const id = searchParams.get("id");

  useEffect(() => {
    if (reponsegetEmpresa.isSuccess) {
      setempresa(reponsegetEmpresa?.data?.data);
      dispatch(alone(reponsegetEmpresa?.data?.data));
    }
    if (responseUsersComuna.isSuccess) {
      setcomunasAsociadas(responseUsersComuna?.data?.data?.docs.map((el) => el.comuna));
    }
  }, [responseUsersComuna.isSuccess, reponsegetEmpresa.isSuccess]);

  useEffect(() => {
    getEmpresa(id);
    getUsersComuna({ page: 0, cantRows: 50, empresas: id });
  }, []);

  if (reponsegetEmpresa.isLoading || responseUsersComuna.isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <HeaderVista
        start={<Volver label="Volver" to={PRIVATE_ROUTES.LIST_EMPRESA} />}
        titulo={"Ficha de " + empresa?.nombre}
        end={
          <>
            <ButtonIcon variant="outlined" color="dark" noLink startIcon={<EditIcon />} handleClick={() => navigate(`${PRIVATE_ROUTES.EDIT_EMPRESA}?id=${empresa?._id}`)}>
              Editar
            </ButtonIcon>
          </>
        }
      />
      <ListDatosEmpresa empresa={empresa} comunasAsociadas={comunasAsociadas} />
      <br />
    </Container>
  );
};

export default EmpresaFichaScreen;
