import { Container } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useGetStatesMutation } from "../../../store/api/endpoints/stateApiSlice";
import { useGetStatesEmpresasMutation } from "../../../store/api/endpoints/stateEmpresasApiSlice";
import { useGetSucesoMutation } from "../../../store/api/endpoints/sucesosApiSlice";
import { useGetTipoCortesMutation } from "../../../store/api/endpoints/tipocortesApiSlice";
import { useGetTipoCortesEmpresasMutation } from "../../../store/api/endpoints/tipocortesEmpresasApiSlice";
import { useGetTipoDaniosMutation } from "../../../store/api/endpoints/tipoDanioApiSlice";
import { useGetTiposIncidenteMutation } from "../../../store/api/endpoints/tipoIncidenteApiSlice";
import { useGetTiposIncidenteEmpresaMutation } from "../../../store/api/endpoints/tipoIncidenteEmpresasApiSlice";
import { useGetUsersMutation } from "../../../store/api/endpoints/userApiSlice";
import { setList } from "../../../store/slices/empresa";
import { ListEstados } from "../../../store/slices/estado";
import { ListEstados as ListEstadosEmpresas } from "../../../store/slices/estadoEmpresa";
import { alone } from "../../../store/slices/sucesos";
import { list as listCortes } from "../../../store/slices/tipoCortes";
import { list as listCortesEmpresas } from "../../../store/slices/tipoCortesEmpresa";
import { listDanio } from "../../../store/slices/tipoDanio";
import { listIncidentes } from "../../../store/slices/tipoIncidentes";
import { listIncidentes as listIncidentesEmpresas } from "../../../store/slices/tipoIncidentesEmpresa";
import { convertDateUtc } from "../../../utils/formatDate";
import { Loading, Volver } from "../../molecule";
import { HeaderVista } from "../../organism";
import ListaDeAtributosSuceso from "../../organism/suceso/FormSuceso";
import FormSucesoCucc from "../../organism/suceso/FormSucesoCUCC";
import FormSucesoEmpresa from "../../organism/suceso/FormSucesoEmpresa";

const SucesoEditTemplate = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [getSucesoAlone, responseSuceso] = useGetSucesoMutation();
  const [getCortes, responseCortes] = useGetTipoCortesMutation();
  const [getEstados, responseStates] = useGetStatesMutation();
  const [getEstadosEmpresas, responseStatesEmpresas] = useGetStatesEmpresasMutation();
  const [getIncidencias, responseIncidencias] = useGetTiposIncidenteMutation();
  const [getIncidenciasEmpresas, responseIncidenciasEmpresas] = useGetTiposIncidenteEmpresaMutation();
  const [getDanios, responseDanios] = useGetTipoDaniosMutation();
  const [getCortesEmpresas, responseCortesEmpresas] = useGetTipoCortesEmpresasMutation();
  const [getUsers, responseUsers] = useGetUsersMutation();

  const id = searchParams.get("id");

  useEffect(() => {
    if (responseSuceso.isSuccess) {
      let sucesoFormatted = JSON.parse(JSON.stringify(responseSuceso.data.data));
      const nuevaFecha = convertDateUtc(sucesoFormatted?.fechahora);
      const splitFecha = nuevaFecha.split(" ");
      sucesoFormatted.fecha = splitFecha[0];
      sucesoFormatted.hora = splitFecha[1];
      getUsers({ page: 0, limit: 50, comunas: sucesoFormatted?.comuna });

      dispatch(alone(sucesoFormatted));
    }
  }, [responseSuceso.isSuccess]);

  useEffect(() => {
    if (responseCortes.isSuccess) {
      dispatch(listCortes(responseCortes?.data?.data?.docs?.map((el) => ({ key: el.codigo, label: el.nombre }))));
    }
    if (responseStates.isSuccess) {
      dispatch(ListEstados(responseStates?.data?.data?.docs?.map((el) => ({ key: el.codigo, label: el.nombre, elige_empresa: el?.elige_empresa }))));
    }
    if (responseStatesEmpresas.isSuccess) {
      dispatch(ListEstadosEmpresas(responseStatesEmpresas?.data?.data?.docs?.map((el) => ({ key: el.codigo, label: el.nombre }))));
    }
    if (responseIncidencias.isSuccess) {
      dispatch(listIncidentes(responseIncidencias?.data?.data?.docs?.map((el) => ({ key: el.codigo, label: el.nombre }))));
    }
    if (responseIncidenciasEmpresas.isSuccess) {
      dispatch(listIncidentesEmpresas(responseIncidenciasEmpresas?.data?.data?.docs?.map((el) => ({ key: el.codigo, label: el.nombre }))));
    }
    if (responseDanios.isSuccess) {
      dispatch(listDanio(responseDanios?.data?.data?.docs?.map((el) => ({ key: el.codigo, label: el.nombre }))));
    }
    if (responseUsers.isSuccess) {
      const newEmpresas = responseUsers?.data?.data?.docs[0]?.empresas_asociadas?.map((el) => ({ key: el._id, label: el.nombre }));
      dispatch(setList(newEmpresas));
    }
    if (responseCortesEmpresas.isSuccess) {
      dispatch(listCortesEmpresas(responseCortesEmpresas?.data?.data?.docs?.map((el) => ({ key: el.codigo, label: el.nombre }))));
    }
  }, [responseCortes.isSuccess, responseStates.isSuccess, responseIncidenciasEmpresas.isSuccess, responseIncidencias.isSuccess, responseDanios.isSuccess, responseStatesEmpresas.isSuccess, responseUsers.isSuccess]);

  const fetchData = useCallback(async () => {
    try {
      await Promise.all([
        getCortes({ page: 0, limit: 50 }),
        getEstados({ page: 0, limit: 50 }),
        getIncidencias({ page: 0, limit: 50 }),
        getIncidenciasEmpresas({ page: 0, limit: 50 }),
        getEstadosEmpresas({ page: 0, limit: 50 }),
        getDanios({ page: 0, limit: 50 }),
        getSucesoAlone({ id, isFicha: false }),
        getCortesEmpresas({ page: 0, limit: 50 }),
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [id, getCortes, getEstados, getIncidencias, getDanios, getSucesoAlone]);

  useEffect(() => {
    fetchData();
  }, []);

  const isGlobalLoading = responseCortes.isLoading || responseStates.isLoading || responseIncidenciasEmpresas.isLoading || responseIncidencias.isLoading || responseDanios.isLoading || responseStatesEmpresas.isLoading;

  if (isGlobalLoading) {
    return <Loading />;
  }
  return (
    <Container>
      <HeaderVista start={<Volver label="Volver" to={`${PRIVATE_ROUTES.FICHA_SUCESOS}?id=${id}`} />} titulo={"Ficha del suceso"} />
      <FormSucesoCucc id={id} />
      <ListaDeAtributosSuceso id={id} />
      <FormSucesoEmpresa id={id} />
      <br />
    </Container>
  );
};

export default SucesoEditTemplate;
