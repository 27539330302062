import React, { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import "../../assets/css/Login.css";
import { Button, Grid } from "@mui/material";
import { Input, PasswordInput } from "../atoms";
import { useLoginMutation } from "../../store/api/endpoints/authApiSlice";
import { setValueSessionStorage } from "../../utils/sessionStorage";
import { useNavigate } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../routes/name-path.routes";
import { useDispatch } from "react-redux";
import { openFeedback } from "../../store/slices/feedback";
import { NOMBRE_APP } from "../../constants/global";

export default function FormLogin() {
  const dispatch = useDispatch();
  const [login, response] = useLoginMutation();
  const navigate = useNavigate();
  const [user, setuser] = useState({
    email: "",
    contrasena: "",
  });

  const submitForm = async (e) => {
    e.preventDefault();
    if (!(user.email || user.password)) {
      dispatch(
        openFeedback({
          severity: "error",
          message: "El correo y contraseña son requeridos",
        })
      );
    }
    login(user)
      .then((value) => {
        let error = value?.error;
        if (error) {
          dispatch(
            openFeedback({
              severity: "error",
              message: error?.data.message,
            })
          );
        } else {
          setValueSessionStorage("token", value.data.token);
          navigate(PRIVATE_ROUTES.HOME);
        }
      })
      .catch((err) => {
        dispatch(
          openFeedback({
            severity: "error",
            message: err,
          })
        );
      });
  };

  const handleChange = (value, name) => {
    setuser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Grid item xs={12} className="container-login">
      <div className="login-component">
        <form onSubmit={submitForm} className="form-login">
          <h3 className="text-welcome">{NOMBRE_APP}</h3>
          <Input
            label="Ingrese su Email"
            value={user?.email}
            fullWidth
            disabled={false}
            type="email"
            variant="standard"
            icon={<PersonIcon />}
            restricciones={["email"]}
            onChange={handleChange}
            autoFocus
            name={"email"}
            required
          />

          <br />
          <br />
          <PasswordInput text="Contraseña" password={user?.contrasena} handleChange={handleChange} name={"contrasena"} disabled={false} />
          <br />
          <br />

          <p>
            <Button type="submit" variant="outlined" color="success">
              Ingresar
            </Button>
          </p>
        </form>
      </div>
    </Grid>
  );
}
