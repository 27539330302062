import React from "react";

export const TableRowIndicadores = ({ row }) => {
  const hasToFooterTable = row?.totalSinAsignarYSinEmpresa !== undefined;

  const getCellStyle = () => {
    return {
      border: "0.3px solid lightgrey",
      width: "10px",
    };
  };

  const styleError = (value) => ({
    backgroundColor: !hasToFooterTable && value > 0 ? "#fac7c7" : "transparent", // Condición para aplicar el fondo rojo claro
  });

  return (
    <tr style={{ height: "10%" }}>
      <td style={{ ...getCellStyle(), textAlign: hasToFooterTable ? "left" : "center" }}>
        <span>{hasToFooterTable ? <b>Totales</b> : row?.comuna}</span>
      </td>
      <td style={{ ...getCellStyle(), ...styleError(row?.countSinEmpresa), textAlign: "right" }}>
        <span>{hasToFooterTable ? <b>{row?.totalSinEstado}</b> : row?.countSinEmpresa}</span>
      </td>
      <td style={{ ...getCellStyle(), ...styleError(row?.countSinEstado), textAlign: "right" }}>
        <span>{hasToFooterTable ? <b>{row?.totalSinEmpresa}</b> : row?.countSinEstado}</span>
      </td>
      <td style={{ ...getCellStyle(), ...styleError(row?.total), textAlign: "right" }}>
        <span>{hasToFooterTable ? <b>{row?.totalSinAsignarYSinEmpresa}</b> : row?.total}</span>
      </td>
    </tr>
  );
};
