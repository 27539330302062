/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "../../../assets/css/vistaFormulario.css";
import { openFeedback } from "../../../store/slices/feedback";
import Loading from "../../elementos/Loading";

import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { gettipoNombreCalle, clean } from "../../../store/slices/tipoNombreCalle";
import FormTIpoDaño from "./FormTipoNombreCalle";

export const TipoNombreCalleNew = ({ createMutation, responseMutation }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const corte = useSelector(gettipoNombreCalle, shallowEqual);

  useEffect(() => {
    if (responseMutation.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha creado nombre de calle!",
        })
      );
      navigate(PRIVATE_ROUTES.LIST_TIPO_NOMBRE);
    }

    if (responseMutation.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: responseMutation?.error?.data?.message,
        })
      );
    }
  }, [responseMutation.isSuccess, responseMutation.isError]);

  useEffect(() => {
    dispatch(clean());
  }, []);

  const handleSubmit = async () => {
    createMutation(corte);
  };

  if (responseMutation.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <FormTIpoDaño onSubmit={handleSubmit} editting={true} />
      <br />
    </>
  );
};
