/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "../../../assets/css/vistaFormulario.css";
import { openFeedback } from "../../../store/slices/feedback";
import Loading from "../../elementos/Loading";

import ListaDeAtributosClientes from "./FormCliente";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { getUser } from "../../../store/slices/user";
import { useCreateUserMutation } from "../../../store/api/endpoints/userApiSlice";
import { useGetEmpresasMutation } from "../../../store/api/endpoints/empresaApiSlice";
import { setList } from "../../../store/slices/empresa";

export const UserNuevo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(getUser, shallowEqual);

  const [createCliente, responseCreateCliente] = useCreateUserMutation();
  const [getEmpresas, reponseEmpresas] = useGetEmpresasMutation();

  useEffect(() => {
    getEmpresas({ page: 0, cantRows: 50 });
  }, []);

  useEffect(() => {
    if (responseCreateCliente.isSuccess) {
      dispatch(
        openFeedback({
          severity: "success",
          message: "¡Se ha creado el usuario!",
        })
      );
      navigate(PRIVATE_ROUTES.LIST_USERS);
    }
    if (reponseEmpresas.isSuccess) {
      let newEmpresas = reponseEmpresas?.data?.data?.docs.map((el) => ({
        codigo: el._id,
        nombre: el.nombre,
      }));
      dispatch(setList(newEmpresas));
    }
    if (responseCreateCliente.isError) {
      dispatch(
        openFeedback({
          severity: "error",
          message: responseCreateCliente?.error?.data?.message,
        })
      );
    }
  }, [responseCreateCliente.isSuccess, responseCreateCliente.isError, reponseEmpresas.isSuccess]);

  const handleSubmit = async () => {
    createCliente(user);
  };

  if (responseCreateCliente.isLoading || reponseEmpresas.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <ListaDeAtributosClientes onSubmit={handleSubmit} editting={true} />
      <br />
    </>
  );
};
