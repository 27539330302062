import moment from "moment-timezone";
import { Global } from "../constants/global";

export const convertDateUtc = (date) => {
  const dateFormat = moment.tz(date, Global.TIMEZONE);
  return dateFormat.format(Global.FormatFullDate);
};

export const formatDate = (date) => {
  return moment.utc(date).format(Global.FormatDate);
};

export const listDatesWithOutCustomRange = [
  { label: "Hoy", value: "today" },
  { label: "Ayer", value: "yesterday" },
  { label: "Últimos 7 días", value: "last7Days" },
  { label: "Últimos 30 días", value: "last30Days" },
  { label: "Mes actual", value: "currentMonth" },
  { label: "Mes anterior", value: "lastMonth" },
  { label: "Últimos 60 días", value: "last60Days" },
];

export const dateRangeMap = {
  today: () => {
    const today = moment();
    return {
      fechaDesde: today.format(Global.FormatDate),
      fechaHasta: today.format(Global.FormatDate),
      horaDesde: moment().set({ hour: 0, minute: 0, second: 0 }).format(Global.FormatTime),
      horaHasta: moment().set({ hour: 23, minute: 59, second: 59 }).format(Global.FormatTime),
      intervalo_fechas: "today",
    };
  },
  yesterday: () => {
    const yesterday = moment().subtract(1, "days");
    return {
      fechaDesde: yesterday.format(Global.FormatDate),
      fechaHasta: yesterday.format(Global.FormatDate),
      horaDesde: moment().set({ hour: 0, minute: 0, second: 0 }).format(Global.FormatTime),
      horaHasta: moment().set({ hour: 23, minute: 59, second: 59 }).format(Global.FormatTime),
      intervalo_fechas: "yesterday",
    };
  },
  last7Days: () => {
    const today = moment();
    const fechaDesde = moment(today).subtract(7, "days").format(Global.FormatDate);
    const fechaHasta = today.format(Global.FormatDate);
    return {
      fechaDesde,
      fechaHasta,
      horaDesde: moment().set({ hour: 0, minute: 0, second: 0 }).format(Global.FormatTime),
      horaHasta: moment().set({ hour: 23, minute: 59, second: 59 }).format(Global.FormatTime),
      intervalo_fechas: "last7Days",
    };
  },
  last30Days: () => {
    const today = moment();
    const fechaDesde = moment(today).subtract(30, "days").format(Global.FormatDate);
    const fechaHasta = today.format(Global.FormatDate);
    return {
      fechaDesde,
      fechaHasta,
      horaDesde: moment().set({ hour: 0, minute: 0, second: 0 }).format(Global.FormatTime),
      horaHasta: moment().set({ hour: 23, minute: 59, second: 59 }).format(Global.FormatTime),
      intervalo_fechas: "last30Days",
    };
  },
  currentMonth: () => {
    const today = moment();
    return {
      fechaDesde: today.startOf("month").format(Global.FormatDate),
      fechaHasta: today.endOf("month").format(Global.FormatDate),
      horaDesde: moment().set({ hour: 0, minute: 0, second: 0 }).format(Global.FormatTime),
      horaHasta: moment().set({ hour: 23, minute: 59, second: 59 }).format(Global.FormatTime),
      intervalo_fechas: "currentMonth",
    };
  },
  lastMonth: () => {
    const today = moment().subtract(1, "month");
    return {
      fechaDesde: today.startOf("month").format(Global.FormatDate),
      fechaHasta: today.endOf("month").format(Global.FormatDate),
      horaDesde: moment().set({ hour: 0, minute: 0, second: 0 }).format(Global.FormatTime),
      horaHasta: moment().set({ hour: 23, minute: 59, second: 59 }).format(Global.FormatTime),
      intervalo_fechas: "lastMonth",
    };
  },
  last60Days: () => {
    const today = moment();
    const fechaDesde = moment(today).subtract(60, "days").format(Global.FormatDate);
    const fechaHasta = today.format(Global.FormatDate);
    return {
      fechaDesde,
      fechaHasta,
      horaDesde: moment().set({ hour: 0, minute: 0, second: 0 }).format(Global.FormatTime),
      horaHasta: moment().set({ hour: 23, minute: 59, second: 59 }).format(Global.FormatTime),
      intervalo_fechas: "last60Days",
    };
  },
  customRange: () => {
    return {};
  },
};
